<template>
  <div class="wrapper">
    <header class="header-fixed">
      <div class="header-main">
        <div class="header-logo">
          <div class="header-logo_img">
            <img src="@/assets/img/paw-logo.png" alt="">
          </div>
          <div class="header-logo_title">CAT MONEY</div>
        </div>
      </div>
    </header>

    <section class="tour-suggest list-order">
      <div class="flex justify-between items-center relative">
        <div class="flex items-center back-btn">
          <router-link to="/wallet">

            <i class="ti ti-chevron-left text-white back-btn-icon"></i>
          </router-link>
        </div>
        <div class="title-primary text-white text-title">Nạp tiền</div>
      </div>

      <div class="change-pass">
        <div class="flex items-center gap-x-1 w-full">
          <i class="ti ti-tag icon-suggest"></i>
          <div class="flex flex-col">
            <p class="suggest-title">Lời khuyên tốt</p>
            <p class="suggest-desc">
              Quý khách nạp tiền vui lòng liên hệ với CSKH !
            </p>
          </div>
        </div>
        <div class="w-full">
          <div class="flex justify-between items-center mb-3">
            <p class="text-16">Số dư tài khoản</p>
            <p class="font-bold text-18">₫ {{ formatMoney(user.balance) }}</p>
          </div>
          <p class="change-pass-text">
            Nhập số tiền nạp<span style="color: red; padding-left: 4px">*</span>
          </p>
          <input type="text" class="change-pass-input " style="padding-left: 5px;" v-model="money_show"
            @input="inputMoney" />
        </div>
        <div class="suggest-deposit-main">
          <div class="suggest-deposit-box" @click="fastMoney(200000)">200,000đ</div>
          <div class="suggest-deposit-box" @click="fastMoney(500000)">500,000đ</div>
          <div class="suggest-deposit-box" @click="fastMoney(1000000)">1,000,000đ</div>
          <div class="suggest-deposit-box" @click="fastMoney(5000000)">5,000,000đ</div>
        </div>

        <button class="save-btn" @click="add">Nạp tiền</button>
      </div>
    </section>

    <Footer />
  </div>
</template>

<script>
import Footer from '@/views/user/components/Footer'
import network from '@/core/network'
export default {
  components: { Footer },
  data() {
    return {
      amount: 0,
      money_show: '',
      user: {}
    }
  },
  mounted() {
    this.getProfile();
  },
  methods: {
    getProfile() {
        network.get('/users/profile').then( res => {
          console.log(res.data.data);
          this.user = res.data.data;
        }).catch(err => {
          console.log(err);
        })
      },
    add() {
      network.post('/requests/recharge', {
        amount: this.amount,
      }).then(res => {
        console.log(res);
        this.$toast.open({
          message: res.data.message,
          type: 'success',
          position: 'top'
        });
      }).catch(err => {
        this.$toast.open({
          message: err.response.data.message,
          type: 'error',
          position: 'top'
        });
      })
    },
    inputMoney() {
      this.money_show = event.target.value.replace(/\D/g, '');

      let number = this.money_show == 0 ? 0 : this.money_show.replace(/[^\d]/g, '');
      this.amount = number;

      this.money_show = this.formatMoney(number)
    },
    fastMoney(money) {
      this.amount = money;
      this.money_show = this.formatMoney(money)
    },
    formatMoney(number) {
       
        return parseInt(number).toLocaleString('vi-VN', {
          useGrouping: true,
          minimumFractionDigits: 0
        }).replace(/\./g, ',');
    }
  }

}
</script>

<style></style>