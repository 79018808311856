<template>
  <footer class="footer-fixed">
    <div class="footer-list">
      <router-link to="/home" class="d-flex flex-column align-items-center pt-2 footer-item" :class="{ ' footer-item_active': $route.path === '/home' }">
        <i class="ti ti-smart-home footer-icon"></i>
        <p class="footer-text">Trang chủ</p>
      </router-link>
      <router-link to="/invest" class="d-flex flex-column align-items-center pt-2 footer-item" :class="{ ' footer-item_active': $route.path === '/invest' }">
        <i class="ti ti-coins footer-icon"></i>
        <p class="footer-text">Đầu tư</p>
      </router-link>
      <router-link to="/wallet" class="d-flex flex-column align-items-center pt-2 footer-item" :class="{ ' footer-item_active': $route.path === '/wallet' }">
        <i class="ti ti-wallet footer-icon"></i>
        <p class="footer-text">Thông tin</p>
      </router-link>
      <router-link to="/rank" class="d-flex flex-column align-items-center pt-2 footer-item" :class="{ ' footer-item_active': $route.path === '/rank' }">
        <i class="ti ti-trophy footer-icon"></i>
          <p class="footer-text">Xếp hạng</p>
      </router-link>
      <router-link to="/my" class="d-flex flex-column align-items-center pt-2 footer-item " :class="{ ' footer-item_active': $route.path === '/my' }">
        <i class="ti ti-mood-crazy-happy footer-icon"></i>
        <p class="footer-text">Tài khoản</p>
      </router-link>
    </div>
  </footer>
</template>

<script>
export default {

}
</script>

<style>

</style>